// const lsa = window.sessionStorage;
const lsa = window.localStorage; // 数据转移保存到localStorage，这样复制链接到新的页面也可以正常加载
export default {
  getItem(key) {
    try {
      return JSON.parse(lsa.getItem(key));
    } catch (err) {
      return null;
    }
  },
  setItem(key, val) {
    lsa.setItem(key, JSON.stringify(val));
  },
  clear() {
    lsa.clear();
  },
  keys() {
    return lsa.keys();
  },
  removeItem(key) {
    lsa.removeItem(key);
  },
};
