import axios from "axios";
const BASE_URL = process.env.VUE_APP_API_URL;
import { Message, MessageBox } from "element-ui";
let msgBool = true; //解决一个页面弹框多次的问题
import NO_MSG_ERROR_CODE from "./code.js";
// import  router from "@/router";
import setting from "./config";
import { blob2Json } from "./help";
import { delCookie } from "../utils/CookieUtils.js";
import { goLoginImip } from "../utils/imip.js";
import Storage from "../utils/Storagehandle.js";

const http = axios.create({
  baseURL: BASE_URL,
  timeout: setting.timeout, // 请求超时时间
});

export const httpWithoutInterceptors = axios.create({
  baseURL: BASE_URL,
  timeout: 30000, // 请求超时时间
});

// 添加request拦截器
http.interceptors.request.use(
  (config) => {
    // console.log(config);
    const language = "zh_CN";
    config.headers = {
      ...config.headers,
      appId: setting.appId,
      language,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 添加response拦截器
http.interceptors.response.use(
  async (response) => {
    if (Object.prototype.toString.call(response.data).slice(8, -1) === "Blob") {
      if (response.data.type === "application/json") {
        response.data = await blob2Json(response.data);
      } else {
        return Promise.resolve(response);
      }
    }

    // 接口状态码为0，一切正常
    if (response.data.code === 0) {
      return Promise.resolve(response.data.data);
    } else {
      // 状态码不为0
      if (
        !NO_MSG_ERROR_CODE.includes(response.data.code) &&
        !response.config.noMsgFlag
      ) {
        Message.error(response.data.msg);
      }
      // 如果用户过期，则弹出过期提示并且跳转登录页面
      if (
        +response.data.code === 14005 ||
        +response.data.code === 12001 ||
        response.data.code === 12002
      ) {
        if (msgBool) {
          msgBool = false;
          MessageBox.alert(response.data.msg, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              msgBool = true;
              // todo: 主应用中如何保存用户信息并进行跳转
              // router.replace({
              //   name: "Login",
              //   query: { redirect: router.currentRoute.fullPath }, //登录后再跳回此页面时要做的配置
              // });
              // this.$router.push({ path: "/#/login" });
              // window.location.href = "/#/login";
            },
          });
        }
      }
      if (
        +response.data.code === 1800 //请重新登录
      ) {
        goLoginImip();
      } else if (
        +response.data.code === 1802 //用户被剔除空间
      ) {
        // delCookie("spaceCode");
        // this.$router.push({ path: "/product/list" });
        let path = "/developer/#/product/list";
        history.pushState(null, path, path);
        window.location.reload();
      } else if (
        +response.data.code === 1803 || //用户被删除  接口返回 您已被移出xxx空间，有疑问请联系管理员
        +response.data.code === 1804

        //用户角色或权限变更
      ) {
        MessageBox.alert(response.data.msg || "权限变更，请重新登录", "提示", {
          confirmButtonText: "我知道了",
          // cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //登出
            delCookie("nickName");
            delCookie("accessToken");
            delCookie("email");
            delCookie("headPhoto");
            Storage.removeItem("permitCodes");
            goLoginImip();
          })
          .catch(() => {
            // this.$router.push({ path: "/product/list" });
            // window.location.reload();
          });
      } else if (+response.data.code === 1805) {
        MessageBox.alert(response.data.msg || "权限变更，请重新登录", "提示", {
          confirmButtonText: "我知道了",
          // cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //重新请求getUserInfo
          })
          .catch(() => {
            // this.$router.push({ path: "/product/list" });
            // window.location.reload();
          });
      }

      return Promise.reject(response.data);
    }
  },
  (error) => {
    console.log(error);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log(401);
          break;
        case 403:
          console.log(403);
          break;
        case 404:
          console.log(404);
          break;
        case 500:
          console.log(500);
          break;
      }
    }
    Message.error("请求失败!");
    return Promise.reject(error);
  }
);

export default http;
