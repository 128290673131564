import { getCookie, encrypSHA256 } from "@/utils/CookieUtils";
import http, { httpWithoutInterceptors } from "./axios";
import { setHeaders } from "./help";

// 封装get请求
function get(url, { ContentType = "application/json", responseType = "" }) {
  return http({
    url,
    method: "GET",
    headers: setHeaders({}, ContentType, responseType),
    data: {},
    responseType: responseType,
  });
}

// 封装post请求
function post(
  url,
  {
    data = {},
    noMsgFlag = false,
    ContentType = "application/json",
    responseType = "",
    onUploadProgress = null,
  }
) {
  return http({
    url,
    method: "POST",
    headers: setHeaders(data, ContentType, responseType),
    data,
    onUploadProgress,
    noMsgFlag,
    responseType,
  });
}

function getFile(url, data = {}) {
  // console.log(process.env, data);
  //加密body(HmacSHA256)
  let sign = "";
  let random = "";
  let dataS = "";
  let OEM_KEY = "";
  // if (Object.keys(data).length !== 0) {
  dataS = JSON.stringify(data);
  // } else {
  //   data = "";
  // }
  if (
    window.location.hostname === "dev-us.dollin.net" ||
    window.location.hostname === "sit-us.dollin.net" ||
    process.env.NODE_ENV == "development"
  ) {
    OEM_KEY = "SIT_4VjZdg19laDoIrut";
  } else {
    OEM_KEY = "PROD_ZvpXaQnlibUri3ZV";
  }
  const OEM_SECRET = "meicloud";
  random = Math.floor(Math.random() * 1000000000000 + 1);
  const str = OEM_SECRET + dataS + random;
  sign = encrypSHA256(str, OEM_KEY);
  // console.log(sign);
  //加密body(HmacSHA256)
  return httpWithoutInterceptors({
    url,
    method: "POST",
    headers: {
      appId: "1011",
      language: data.language,
      random,
      sign,
      "Content-Type": "application/json",
      accessToken: getCookie("accessToken"),
      reqId: Date.now(),
      responseType: "blob",
    },
    responseType: "blob",
    data,
  });
}

export default {
  get,
  post,
  getFile,
};
