import { getCookie } from "@/utils/CookieUtils";
import Storage from "@/utils/Storagehandle";
const state = () => ({
  account: {
    nickName: getCookie("nickName"),
    accessToken: getCookie("accessToken"),
    email: getCookie("email"),
    headPhoto: getCookie("headPhoto"),
    currentRoute: Storage.getItem("currentRoute") || null,
  },
});

// actions
const actions = {
  setAccountInfo({ commit }, account) {
    commit("setAccountInfo", account);
  },
};

// mutations
const mutations = {
  setAccountInfo(state, account) {
    state.account = account;
  },
  setProductCache(state, route) {
    Storage.setItem(`currentRoute`, route);
    state.currentRoute = route;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
