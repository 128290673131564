import Vue from "vue";
import VueRouter from "vue-router";
import { getCookie } from "@/utils/CookieUtils";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "*",
    name: "main",
    component: () => import("@/views/index.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  //全局钩子函数
  to.matched.some((route) => {
    if (route.name === "Login") {
      next();
    } else {
      if (getCookie("accessToken") !== "") {
        next();
      } else {
        next({ name: "Login", params: { path: route.path } });
      }
    }
  });
});

// router.beforeEach((to, from, next) => {
//   if (document.cookie.includes("mulang-token")) {
//     if (to.path.toLowerCase() === "/login") {
//       next("/management/lexicon");
//     } else {
//       // console.log('to', to)
//       // console.log('form', from)

//     }
//   } else {
//     // 注意：client_id和redirect_uri,dev和sit环境一套，uat和生产另外一套
//     //预留，服务平台不区分环境的client_id
//     const clientMap = {
//       dev: {
//         client_id: "7KzXEan2BnM9AZSY",
//         host: "signinuat.midea.com",
//       },
//       uat: {
//         client_id: "7KzXEan2BnM9AZSY",
//         host: "signin.midea.com",
//       },
//     };
//     let url = "";
//     if (
//       window.location.hostname === "localhost" ||
//       window.location.hostname === "dev-us.dollin.net" ||
//       window.location.hostname === "sit-us.dollin.net"
//     ) {
//       url = `https://${clientMap.dev.host}/oauth2.0/authorize?response_type=code&client_id=${clientMap.dev.client_id}&redirect_uri=${window.location.origin}/v1/product/login`;
//     } else {
//       url = `https://${clientMap.uat.host}/oauth2.0/authorize?response_type=code&client_id=${clientMap.uat.client_id}&redirect_uri=${window.location.origin}/v1/product/login`;
//     }

//     window.location.href = url;
//   }
// });

export default router;
