<template>
  <div class="page">版本：V{{ version }}</div>
</template>

<script>
export default {
  name: "OEMVersion",
  data() {
    return {
      version: "1.9.0",
    };
  },
};
</script>

<style lang="less" scoped>
.page {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.52);
  line-height: 22px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 10px;
}
</style>
