<template>
  <div class="menu-main">
    <!-- <ul class="menu-list">
      <li
        class="menu-item"
        :class="currentName === item.name && 'menu-item-active'"
        v-for="(item, index) in navMenuList"
        :key="index"
        @click="goTo(item)"
      >
        <div class="menu-name">{{ item.name }}</div>
        <div class="menu-active"></div>
      </li>
    </ul> -->

    <el-menu
      @select="handleSelect"
      :default-active="menuIndex"
      class="menu-list"
      background-color="transparent"
      mode="horizontal"
    >
      <template v-for="item in navMenuList">
        <template v-if="item.submenu">
          <OEMSubMenu :key="item.name" :menu="item" :index="item.path" />
        </template>
        <div v-else-if="item.name === '文档中心'" :key="item.name" class="el-menu-item" style="background: transparent !important;" @click="handleSelect(item.name)">
          <div style="text-align: center">{{ item.name }}</div>
        </div>
        <el-menu-item :key="item.name" :index="item.name" v-else>
          <div style="text-align: center">{{ item.name }}</div>
          <div class="menu-active"></div>
        </el-menu-item>
      </template>
    </el-menu>

    <!-- todo: 使用router-link渲染报错
    Cannot read properties of undefined (reading 'resolve') -->
    <!-- <router-link to="/">Go to Home</router-link> -->
  </div>
</template>

<script>
import Storage from "@/utils/Storagehandle";
import { getUserInfo } from "../../api/login";
import { setCookie } from "../../utils/CookieUtils";

// import { setCookie } from "../../utils/CookieUtils";
export default {
  name: "OEMMenu",
  data() {
    return {
      menuIndex: "产品管理", // 当前激活的菜单名字
      navMenuList: [
        // 因菜单拥挤，暂时屏蔽
        // {
        //   name: "数据首页",
        //   path: "/developer/#/data",
        //   model: "data",
        // },
        {
          name: "产品管理",
          path: "/developer/#/product/list",
          model: "product",
        },
        {
          name: "生产管理",
          path: "/developer/#/production/list",
          model: "production",
        },
        {
          name: "APP管理",
          path: "/developer/#/app",
          model: "app",
        },
        {
          name: "增值服务",
          path: "/developer/#/service",
          model: "service",
        },
        {
          name: "固件管理",
          path: "/operator/#/",
          model: "hardware",
          submenu: [
            {
              name: "平台固件管理",
              path: "/operator/#/hardware/platform",
            },
            {
              name: "生态固件管理",
              path: "/operator/#/hardware/ecology",
            },
          ],
        },
        {
          name: "运营管理",
          path: "/developer/#/",
          model: "operation",
          submenu: [
            {
              name: "用户反馈",
              path: "/developer/#/operation/userFeedback",
            },
            {
              name: "日志分析",
              path: "/developer/#/operation/logAnalysis",
            },
          ],
          // submenu: [
          //   {
          //     name: "用户反馈",
          //     path: "/developer/#/operation",
          //   },
          // {
          //   name: "云菜谱",
          //   path: "/operator/cooking/classList",
          //   submenu: [
          //     {
          //       name: "食谱管理",
          //       path: "/operator/cooking/menuList",
          //     },
          //     {
          //       name: "分类管理",
          //       path: "/operator/cooking/classList",
          //     },
          //   ],
          // },
          // ],
        },
        {
          name: "文档中心",
          path: "/developer/#/document",
          model: "document",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        const pathname = window.location.pathname;
        console.log("@@@@@@@@@@@@@@", pathname);
        let path = `${pathname}#` + this.$route.fullPath;
        let find = this.navMenuList.find((f) => f.path === path);
        // console.log("@@@@@@@@@@@@@@", find);
        if (find) {
          this.menuIndex = find.name;
          console.log("------", this.menuIndex);
        } else {
          // 如果没有找到，进入的就是二级子目录
          const pathKeys = this.$route.fullPath.split("/");
          find = this.navMenuList.find((f) => f.model === pathKeys[1]);
          if (pathKeys && find?.submenu) {
            // fix: 子路径前后包含关系找反
            const subPathKey = find.submenu.find(
              (_i) =>
                _i.path
                  .split("/operator/#")
                  .some((_j) => this.$route.fullPath.includes(_j)) ||
                _i.path
                  .split("/developer/#")
                  .some((_j) => this.$route.fullPath.includes(_j))
            );
            console.log("find", subPathKey);
            this.menuIndex = subPathKey?.name;
            console.log("---!!!---", this.menuIndex);
          } else if (find) {
            this.menuIndex = find.name;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    getUserInfo()
      .then((res) => {
        console.log("#####################--getUserInfo", res);
        Storage.setItem("permitCodes", res.permitCodes);

        this.$store.commit("user/setUserInfo", res);
        let expireTime = 60 * 60 * 24;
        // setCookie("nickName", res.uid, expireTime);
        // setCookie("email", res.email, expireTime);
        // setCookie("headPhoto", res.headPhoto, expireTime);
        setCookie("isAdmin", res.isAdmin, expireTime); //是否是超级管理员
        setCookie("spaceCode", res.curSpace, expireTime);
        setCookie("spaceList", res.spaceList, expireTime);
        // setCookie("accessToken", res.casAccessToken, expireTime);
      })
      .then(() => {});
  },
  methods: {
    handleSelect(key) {
      console.log(key, "handleSelect");
      if (key === "文档中心") {
        window.open("https://docs.aiiciot.com/holabrain/", "_blank");
        return;
      }
      if (key === "平台固件管理" || key === "生态固件管理") {
        const firmware = this.navMenuList.find((f) => f.name === "固件管理");
        let find = firmware.submenu.find((f) => f.name === key);
        history.pushState(null, find.path, find.path);
        return;
      }else if (key === "用户反馈" || key === "日志分析") {
        const operation = this.navMenuList.find((f) => f.name === "运营管理");
        let find = operation.submenu.find((f) => f.name === key);
      console.log("find", find.path);
        history.pushState(null, find.path, find.path);
        return;
      }
      let find = this.navMenuList.find((f) => f.name === key);
      history.pushState(null, find.path, find.path);
    },
    // todo: main中使用的store，是子应用的store，main中是否可以有自己的store
    // goTo(item) {
    //   console.log(this.$store);
    //   this.currentName = item.name;
    //   Storage.setItem("currentRoute", item);
    //   history.pushState(null, item.path, item.path);
    // },
  },
};
</script>

<style lang="less">
.menu-main {
  height: 52px;
}
.sub-menu-list {
  background: #fff;
}
.menu-list {
  // background: transparent;
  border-bottom: none !important;
  li {
    color: #fff;
  }
}
.menu-list .el-submenu__title {
  // width: 120px;
  // padding-left: 0 !important;
}
.el-submenu > .el-submenu__title:hover {
  color: auto !important;
}

.el-menu-item {
  // display: inline-block;
  width: 114px;
  height: 52px !important;
  line-height: 52px !important;
  padding-top: 16px;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  border: none !important;
  position: relative;
  text-align: center;
}

.el-menu--horizontal > .el-menu-item {
  color: #fff !important;
}

.el-menu--horizontal > .el-submenu > .el-submenu__title {
  color: #fff !important;
  // padding-left: 0;
}
.el-menu--popup {
  min-width: 134px !important;
}
.el-submenu .el-menu-item {
  min-width: 134px !important;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}

//
.menu-list .is-active {
  background: transparent !important;
  .menu-active {
    height: 8px;
    background-image: url("../../assets/images/nav_tab_active.png");
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
