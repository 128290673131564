export const goLoginImip = () => {
  // 注意：client_id和redirect_uri,dev和sit,uat环境一套，生产另外一套
  //预留，服务平台不区分环境的client_id
  const clientMap = {
    dev: {
      client_id: "7KzXEan2BnM9AZSY",
      host: "signinuat.midea.com",
    },
    prod: {
      client_id: "7KzXEan2BnM9AZSY",
      host: "signin.midea.com",
    },
  };
  let url = "";
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev-us.dollin.net" ||
    window.location.hostname === "sit-us.dollin.net" ||
    window.location.hostname === "uat-us.dollin.net"
  ) {
    url = `https://${clientMap.dev.host}/oauth2.0/authorize?response_type=code&client_id=${clientMap.dev.client_id}&redirect_uri=${window.location.origin}/v1/product/login`;
  } else {
    url = `https://${clientMap.prod.host}/oauth2.0/authorize?response_type=code&client_id=${clientMap.prod.client_id}&redirect_uri=${window.location.origin}/v1/product/login`;
  }

  window.location.href = url;
};
