<template>
  <el-submenu
    :popper-append-to-body="false"
    popper-class="sub-menu-list"
    :index="menu.name"
  >
    <template slot="title"
      >{{ menu.name }}
      <div class="menu-active"></div>
    </template>
    <template v-for="(sub, index) in menu.submenu">
      <template v-if="sub.submenu">
        <!-- vue循环嵌套组件 -->
        <OEMSubMenu :index="sub.name" :menu="sub" :key="sub.name + index" />
      </template>
      <el-menu-item :index="sub.name" v-else :key="sub.name + index">{{
        sub.name
      }}</el-menu-item>
    </template>
  </el-submenu>
</template>

<script>
export default {
  name: "OEMSubMenu",
  props: {
    menu: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log("开始渲染menu子节点：", this.menu);
  },
  methods: {},
};
</script>

<style lang="less">
.el-submenu__title {
  display: inline-block;
  width: 120px;
  padding: 0;
  margin: 0;
  height: 52px !important;
  line-height: 52px !important;
  vertical-align: middle;
  text-align: center;
}
// .el-menu--popup {
//   background: #ffffff;
//   box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
//   border-radius: 4px !important;
//   width: 105px;
// }
.el-menu--popup .el-menu-item:hover {
  background-color: #fff !important;
}
.el-menu--popup .el-menu-item {
  background-color: #fff !important;
  width: 120px;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.el-submenu:hover {
  background-color: transparent !important;
}
/* .el-menu--popup {
  color: #000;
} */
.menu-list .el-menu-item:hover {
  background-color: transparent !important;
  /* color: #fff !important; */
}
.is-active {
  .menu-active {
    height: 8px;
    background-image: url("../../assets/images/nav_tab_active.png");
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #00bdff;
}
</style>
