const state = () => ({
  langList: [
    // 语言列表
    {
      titile: "English",
      desc: "English",
    },
    {
      titile: "Simplified Chinese",
      desc: "简体中文",
    },
  ],
  portList: [],
  tabIndex: localStorage.getItem("tabIndex") || 0, // 默认选中
  portIndex: 0,
});

// mutations
const mutations = {
  setTabIndex(state, index) {
    localStorage.setItem("tabIndex", index);
    state.tabIndex = index;
  },
  setPortList(state, val) {
    state.portList = val;
  },
  setPortIndex(state, index) {
    localStorage.setItem("portIndex", index);
    state.portIndex = index;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
