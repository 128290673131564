import "./public-path";
import { registerMicroApps, setDefaultMountApp } from "qiankun";
import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store/index";
import router from "./router";
import http from "./http";

// 全局组件自动注册
import "./components";

// todo: 主应用不需要elementui，尝试剥离掉
Vue.use(ElementUI);

Vue.config.productionTip = false;

// 初始化主应用
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// 将主应用axios实例挂载到全局
window.$http = http;

const getEntry = (url) => {
  return process.env.NODE_ENV === "development"
    ? url
    : url.replace(/.*\/\/.+:\d+/, "");
};

// 注册子应用
registerMicroApps([
  {
    name: "platform-subapp-developer",
    entry: getEntry("//localhost:8081/platform-subapp/developer/"),
    container: "#container",
    activeRule: "/developer",
  },
  {
    name: "platform-subapp-operator",
    entry: getEntry("//localhost:8082/platform-subapp/operator/"),
    container: "#container",
    activeRule: "/operator",
  },
]);

// 设置默认子应用
setDefaultMountApp("/developer/");
// setDefaultMountApp("/operator/");

// 启动qiankun
// start();
