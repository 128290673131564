<template>
  <div id="app"> 
    <!-- 主应用的menu，所有子应用都要使用 -->
    <!-- <el-header style="padding: 0; margin-bottom: 26px">
      <OEMHeader></OEMHeader>
    </el-header> -->

    <!-- 承接主应用的page -->
    <router-view />

    <!-- 承接子应用的容器 -->
    <!-- <div id="container"></div> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
html, body { 
  height: 100%
}
* { 
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.flex{
  display: flex;
  flex-wrap: wrap;
}

/*滚动条整体样式*/
::-webkit-scrollbar {
  /*高宽分别对应横竖滚动条的尺寸*/
  /* Y轴/竖的滚动条的宽度 */
  width: 6px;
  /* X轴/横滚动条的高度 */
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  background: #b8b7b7;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 6px;
  background: transparent;
}


.el-select .el-input.is-disabled .el-input__inner,.el-input.is-disabled .el-input__inner{
  /* 输入框 下拉框超过宽度 省略号显示 */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
