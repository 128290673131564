<template>
  <div class="portrait-main">
    <!-- <div @click="goSpaceManage" class="space-container">
      <div class="manage-space-title">空间管理</div>
      <img :src="switchImg" class="switch-img" />
    </div> -->
    <el-popover
      placement="bottom"
      trigger="hover"
      :visible-arrow="false"
      style="padding: 0"
      :disabled="account.spaceList.length === 0"
    >
      <div
        style="display: -webkit-flex; flex-direction: row; align-items: center"
        slot="reference"
      >
        <div class="current-space-name" style="align-self: center">
          {{ account.spaceName || "暂无加入空间" }}
        </div>
        <img :src="switchImg" class="switch-img" v-if="account.spaceName" />
      </div>
      <div class="space-list-container">
        <div class="space-scroll">
          <div
            v-for="(item, index) in account.spaceList"
            :key="index"
            class="space-row-item"
            @click="switchSpace(item)"
          >
            <div
              class="overflow-submenu"
              :style="{
                color:
                  account.spaceName === item.spaceName
                    ? '#0397FF'
                    : 'rgba(0,0,0,0.65)',
              }"
            >
              {{ item.spaceName }}
            </div>
            <i
              class="el-icon-check"
              style="color: #0397ff"
              v-if="account.spaceName === item.spaceName"
            ></i>
          </div>
        </div>
        <div class="space-detail-line"></div>
        <div @click="goSpaceManage" class="space-manage-container">
          <img :src="settingImg" class="setting-img" />
          <div class="manage-space-title">空间管理</div>
        </div>
      </div>
    </el-popover>
    <div class="divider"></div>
    <div @click="goOperateLog" class="log-container">
      <img :src="logImg" class="log-img" />
      <div class="log-title">操作日志</div>
    </div>
    <div class="divider"></div>

    <el-popover placement="bottom" :visible-arrow="false">
      <div style="display: -webkit-flex; flex-direction: row" slot="reference">
        <img :src="accountImg" class="portrait" />
        <div class="user-name" style="align-self: center">
          {{ account.uid }}
        </div>
      </div>

      <div class="user-detail">
        <img :src="accountImg" class="detail-portrait" />
        <div class="detail-user-name">{{ account.uid }}</div>
        <!-- <div class="detail-user-email">{{ account.email }}</div> -->
        <div class="detail-line"></div>
        <div class="detail-logout" @click="logout">退出登录</div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { delCookie } from "@/utils/CookieUtils";
import Storage from "@/utils/Storagehandle";
import { loginOutAPi } from "../../api/login";
// import { switchSpaceApi } from "../../api/space";
import logImg from "../../assets/images/ico_operateLog.png";
import switchImg from "../../assets/images/icon_switch.png";
import { getCookie, setCookie } from "../../utils/CookieUtils";
import accountImg from "../../assets/images/ic_n_portrait_s@2x.png";
import settingImg from "../../assets/images/ico_space_manage.png";
import { switchSpaceApi } from "../../api/space";

export default {
  name: "OEMPortrait",
  computed: mapState({
    account(state) {
      console.log(state, "!!!!!!!!!!!!!!!!!!!!");
      const { userInfo = {} } = state.user;
      const { uid } = userInfo;
      const spaceList = state.user?.userInfo?.spaceList || [];
      console.log(spaceList, uid, "---------------------");
      const spaceCode = getCookie("spaceCode");
      let spaceName = "";
      if (spaceList && spaceList?.length > 0) {
        spaceName = spaceList.find(
          (item) => item.spaceCode === spaceCode
        )?.spaceName;
      }
      console.log(spaceName, "spaceName");
      console.log(spaceList, "spaceList");
      return { ...state.account?.account, spaceName, spaceList, uid };
    },
  }),
  data() {
    return {
      showDetailContent: false,
      logImg,
      switchImg,
      accountImg,
      settingImg,
    };
  },

  methods: {
    switchSpace(item) {
      if (item.spaceCode === getCookie("spaceCode")) {
        return;
      }
      // 二次确认弹框
      this.$confirm(
        "切换空间后，您将无法访问当前空间的数据和信息",
        "确认切换空间吗",
        {}
      )
        .then(() => {
          switchSpaceApi({ spaceCode: item.spaceCode })
            .then((res) => {
              console.log(res, "切换空间");
              if (res) {
                this.$message.success("切换成功");
                setCookie("spaceCode", item.spaceCode);
                //路由reset到首页
                // this.$router.push({ path: "/product/list" }); //用这种方法会导致operate模块跳转developer模块拼接路由错误
                let path = "/developer/#/product/list";
                history.pushState(null, path, path);

                location.reload();
              } else {
                this.$message.error(res.msg || "切换空间失败");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});

      // switchSpaceApi({ spaceCode: item.spaceCode, uid: this.account.uid }).then(
      //   (res) => {
      //     console.log(res);
      //     setCookie("spaceCode", item.spaceCode);
      //     this.$message.success("切换成功");
      //     location.reload();
      //   }
      // );
    },
    showDetail() {},
    async logout() {
      delCookie("nickName");
      delCookie("accessToken");
      delCookie("email");
      delCookie("headPhoto");
      Storage.removeItem("permitCodes");
      // this.$router.push({ path: "/login" });
      // window.location.href = "/#/login";
      const res = await loginOutAPi().then(() => {
        // if (
        //   window.location.hostname === 'dev-i18n.dollin.net' ||
        //   window.location.hostname === 'sit-i18n.dollin.net' ||
        //   window.location.hostname === 'localhost'
        // ) {
        //   this.$store.commit('user/logout')
        //   location.href = `https://signinuat.midea.com/logout?service=https://${signin}`
        // } else {
        //   this.$store.commit('user/logout')
        //   location.href = 'https://signin.midea.com/logout?service=https://i18n.dollin.net'
        // }
        const clientMap = {
          dev: {
            client_id: "7KzXEan2BnM9AZSY",
            host: "signinuat.midea.com",
          },
          uat: {
            client_id: "7KzXEan2BnM9AZSY",
            host: "signin.midea.com",
          },
        };
        let url = "";
        if (
          window.location.hostname === "localhost" ||
          window.location.hostname === "dev-us.dollin.net" ||
          window.location.hostname === "sit-us.dollin.net" ||
          window.location.hostname === "uat-us.dollin.net"
        ) {
          url = `https://${clientMap.dev.host}/logout?service=us.dollin.net/#/login`;
        } else {
          url = `https://${clientMap.uat.host}/logout?service=us.dollin.net/#/login`;
        }
        window.location.href = url;
      });
      console.log("登出", res);
    },
    goSpaceManage() {
      let path = "/developer/#/space";
      history.pushState(null, path, path);
    },
    goOperateLog() {
      let path = "/developer/#/log/list";
      history.pushState(null, path, path);
    },
  },
};
</script>

<style lang="less" scoped>
.overflow-submenu {
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 145px; //超过10个字省略
}
.portrait-main {
  height: 30px;
  margin-top: 11px;
  border-left: 1px solid #ffffff26;
  display: flex;
  align-items: center;
  margin-right: 24px;
  position: relative;
}

.portrait {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/images/ic_n_portrait_s@2x.png");
  background-size: 100%;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}
.space-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.switch-img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin-left: 4px;
}
.space-list-container {
  padding-top: 16px;
  min-width: 200px;
}
.space-scroll {
  max-height: 400px;
  overflow: scroll;
}
.current-space-name {
  font-size: 14px;
  color: #ffffff;
  text-align: right;
  line-height: 22px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 145px; //超过10个字省略
  white-space: nowrap;
  margin-left: 10px;
}
.space-row-item {
  padding: 0px 16px 16px 16px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.space-detail-line {
  width: 100%;
  height: 1px;
  border: 1px solid #e9e9e9;
}
.space-manage-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px 0px 0px 0px;
}
.setting-img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.manage-space-title {
  font-size: 14px;
  color: #000000;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
}
.log-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.log-img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: 12px;
}
.log-title {
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  margin-right: 12px;
  cursor: pointer;
}
.user-name {
  min-width: 47px;
  height: 22px;
  font-family: PingFangSC-Regular;
  line-height: 22px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
}

.user-detail {
  // width: 120px;
  // height: 120px;
  // border: 1px solid #e9e9e9;
  // border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail-portrait {
  width: 30px;
  height: 30px;
  background-image: url("../../assets/images/ic_n_portrait_s@2x.png");
  background-size: 100%;
}

.detail-user-name {
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  line-height: 16px;
  font-weight: 400;
  margin-top: 3px;
}

.detail-user-email {
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 16px;
  font-weight: 400;
}

.detail-line {
  width: 100%;
  height: 1px;
  border: 1px solid #e9e9e9;
  margin-top: 10px;
}

.detail-logout {
  height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  margin-top: 7px;
  cursor: pointer;
}
.divider {
  width: 1px;
  background: rgba(255, 255, 255, 0.15);
  height: 30px;
}
</style>
