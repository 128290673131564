import api from "@/http";
// 登录
function loginApi(params) {
  const url = "/v1/product/login";
  return api.get(url, { data: params });
}

// 登出
function loginOutAPi(params) {
  const url = "/v1/product/loginout";
  return api.get(url, { data: params });
}

// 用户基础信息
function getUserInfo(params) {
  const url = "/v1/product/get/userinfo";
  return api.post(url, { data: params });
}

export { loginApi, loginOutAPi, getUserInfo };
