// 接口错误码不弹出Message的列表
export default [
  1101, //产品上线失败
  12001, // 令牌过期
  12002, // 令牌过期，怎么会有两个令牌过期的状态呢
  14004, // 用户存在，密码错误
  14012, // 用户不存在
  1104, //上传固件版本号过低
  1107, //上传设备ID文件错误
  14005, //token失效
  2203001, //原有插件版本存在线上版本，请先下线相关插件版本
  // 12002, // token is invalid
];

// const CODES = {
//   CODE_USER_NO_EXIST: 14012,
// };
// export default CODES
