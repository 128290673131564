import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import product from "./modules/product";
import alexaLogin from "./modules/alexa-login";
import user from "./modules/user";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    product,
    alexaLogin,
    user,
  },
  state: {
    count: 0,
  },
  mutations: {
    add(state) {
      console.log("start mutations add");
      state.count++;
    },
  },
});

window.mainAppStore = store;

export default store;

/** account
 *
 * 根目录下定义
 * 引用值：this.$store.state.count
 * 改变值：this.$store.commit("add");
 *
 * modules中定义
 * 引用值state：this.$store.state.modulesDemo.count
 * 改变值mutations：this.$store.commit("demoCountAdd");      只能同步操作，actions最终也是走mutations
 * 改变product模块中的值：this.$store.commit("product/xxx", {});
 *
 * getters：this.$store.getters.xxx     取值
 * actions：this.$store.dispatch('xxx')     可包含异步操作
 * xxx：定义的方法名
 *
 * 在dom中引用值
 * {{ $store.state.xxx }}
 * {{ $store.state.modulesDemo.xxx }}
 *
 *
 */
