<template>
  <div class="header-main">
    <div class="header-bg">
      <div class="nav"></div>
      <div class="nav-content">
        <el-tooltip :content="version" placement="bottom" effect="light">
          <div class="nav-logo"></div>
        </el-tooltip>
        <OEMMenu />
        <div class="nav-right">
          <OEMPortrait />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OEMHeader",
  components: {},
  data() {
    return {
      version: "1.9.0",
    };
  },
};
</script>

<style lang="less" scoped>
.header-main {
  width: 100%;
  height: 60px;
}

.header-bg {
  width: 100%;
  height: 52px;
  background-color: #000;
  position: relative;
}

.nav {
  width: 100%;
  height: 60px;
  background-image: url("../../assets/images/nav.png");
  background-size: 100% 100%;
}

.nav-content {
  width: 100%;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  width: 186px;
  height: 29px;
  background-image: url("../../assets/images/nav_logo.png");
  background-size: 100% 100%;
  margin-left: 16px;
  margin-top: 14px;
}

.nav-right {
  display: flex;
}
</style>
